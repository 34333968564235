<template>
    <div class="columns is-centered">
        <div class="column is-half px-6 mt-3">
            <h2 class="title has-text-weight-bold">Log in to Jacobs Tools</h2>
            <p class="subtitle has-text-grey is-6">Welcome back! Select method to log in:</p>
            <div class="columns">
                <div class="column">
                    <a class="button is-link is-medium is-fullwidth"
                        :href="autodeskUrl">
                        <span class="icon">
                            <img class="autodesk-icon"
                                src="/static/autodesk-logo-white.svg">
                        </span>
                        <span>Autodesk</span>
                    </a>
                </div>
                <div class="column">
                    <a class="button is-dark is-medium is-fullwidth"
                        :href="pingUrl">
                        <span class="icon">
                            <span class="mdi mdi-microsoft"></span>
                        </span>
                        <span>Jacobs</span>
                    </a>
                </div>
            </div>
            <div class="has-text-centered has-text-grey p-3">
                Jacobs staff who are logged in to their design application with a non jacobs.com email address
                should use Content Lab credentials.
            </div>
            <div v-if="!showLocal"
                class="has-text-centered has-text-grey p-3">
                <a @click="showLocal = true">
                    Use Content Lab credentials
                </a>
            </div>
            <div v-if="showLocal">
                <div class="field">
                    <div class="control has-icons-left">
                        <span class="icon is-small is-left">
                            <span class="mdi mdi-email"></span>
                        </span>
                        <input v-model="email"
                            @keydown.enter="doLogin"
                            class="input"
                            type="email"
                            placeholder="Email address"></input>
                    </div>
                </div>
                <div class="field">
                    <div class="control has-icons-left">
                        <span class="icon is-small is-left">
                            <span class="mdi mdi-lock"></span>
                        </span>
                        <input v-model="password"
                            @keydown.enter="doLogin"
                            class="input"
                            type="password"
                            placeholder="Password"></input>
                    </div>
                </div>
                <router-link :to="{ name: 'password-reset' }"
                    class="is-pulled-right p-4">
                    Forgot password?
                </router-link>
                <div class="control my-5">
                    <a v-if="formValid"
                        @click="doLogin"
                        type="button"
                        class="button is-link is-medium is-fullwidth">Log in</a>
                    <a v-else
                        disabled
                        type="button"
                        class="button is-link is-medium is-fullwidth">Enter a valid email and password</a>
                </div>
                <div class="has-text-grey has-text-centered my-5">
                    Don't have an account?
                    <router-link :to="{ name: 'registration' }">
                        Create an account
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';
import { getDomainName, showMessage, validateEmail } from '@/utils';
import Cookies from 'js-cookie';

export default {
    data() {
        return {
            email: '',
            password: '',
            showLocal: false,
            error: false,
        };
    },
    methods: {
        async doLogin() {
            if (!this.formValid)
                return;

            const data = {
                username: this.email,
                password: this.password
            };

            let resp = await fetch(`${process.env.VUE_APP_API_URL_V1}/auth/login/`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (process.env.VUE_APP_API_URL_V1 != 'https://dev.jacobstools.com/api/v1') {
                let respData = await resp.json()

                // Session cookie is not being set by the server so do it here
                // Relies on SESSION_COOKIE_HTTPONLY = False in the Django settings
                const domain = getDomainName();
                Cookies.set('sessionid', respData.sessionid, { domain });
                Cookies.set('csrftoken', respData.csrftoken, { domain });
            }

            this.error = !resp.ok;

            if (resp.ok) {
                showMessage(`User with email ${this.email} successfully authenticated`);
                await this.$store.dispatch("users/loadCurrentUser");

                let user = this.$store.state.users.currentUser;
                if (user.isIncomplete) {
                    showMessage("Please complete your user details");
                    router.push({ name: 'user-current' });
                } else {
                    router.push({ name: 'home' });
                }
            } else {
                showMessage(`User with email ${this.email} could not be authenticated`, 'danger');
            }
        },
    },
    computed: {
        pingUrl() {
            const params = new URLSearchParams({
                response_type: 'code',
                grant_type: 'authorization_code',
                code_challenge_method: 'S256',
                client_id: process.env.VUE_APP_PING_CLIENT_ID,
                code_challenge: process.env.VUE_APP_PING_CODE_CHALLENGE,
                scope: "data:read openid",
                redirect_uri: `${process.env.VUE_APP_API_URL_V1}/core/ping-callback/`,
                state: location.origin,
            });

            return `${process.env.VUE_APP_PING_AUTH_PATH}/as/authorize/?${params}`;
        },
        autodeskUrl() {
            const params = new URLSearchParams({
                response_type: 'code',
                client_id: process.env.VUE_APP_AUTODESK_CLIENT_ID,
                scope: "data:read",
                redirect_uri: `${process.env.VUE_APP_API_URL_V1}/core/forge-callback/`,
                state: location.origin,
            });

            return `${process.env.VUE_APP_AUTODESK_AUTH_PATH}/authorize?${params}`;
        },
        formValid() {
            return this.email && this.password && validateEmail(this.email);
        }
    }
};
</script>

<style lang="sass" scoped>
.autodesk-icon
    height: 75px

.buttons 
    display: block
</style>
