<template></template>
<script>
import http from "@/http";
import { showMessage, getDomainName } from '@/utils';
import Cookies from 'js-cookie';

export default {
    async mounted() {
        await http.post(`${process.env.VUE_APP_API_URL_V1}/auth/logout/`);
        await this.$store.dispatch("users/logoutCurrentUser");

        if (process.env.VUE_APP_API_URL_V1 != 'https://dev.jacobstools.com/api/v1') {
            const domain = getDomainName();
            Cookies.remove('sessionid', { domain });
            Cookies.remove('csrftoken', { domain });
        }

        showMessage('You are logged out');
        this.$router.push({ name: 'home' });
    }
};
</script>